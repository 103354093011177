import axios from "axios";

const BASE_URL = process.env.VUE_APP_SERVER_API;
// axios.defaults.withCredentials = true
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("JWT_Access");

export default {
  /*
   * Function: updateToken
   * Params: macAddress, deviceName, appToken, appName
   * */
  async updateFirebaseToken(macAddress, deviceName, appToken, appName) {
    const url = `${BASE_URL}/api/v1/user/update-apptoken`;
    let res = [];
    await axios
      .post(
        url,
        {
          macAddress: macAddress,
          "device-name": deviceName,
          appToken: appToken,
          app_name: appName,
        },
        {
          headers: {
            // 'X-CSRFToken': Cookie.get('csrftoken')
          },
          // withCredentials: true
        }
      )
      .then((response) => (res = response))
      .catch((error) => console.log(error));
    return res;
  },

  /*
   * Function: getListAllBlocks
   * Params:
   * */
  async getListAllUsersInBlockManager() {
    const url = `${BASE_URL}/api/v1/user/users_in_block_manager`;
    let res = [];
    await axios
      .get(url) //, {withCredentials: true})
      .then((response) => (res = response.data.dt))
      .catch((error) => console.log(error));
    return res;
  },

  /*
   * Function: getListAllBlocks
   * Params:
   * */
  async getListAllAreasInBlockManager() {
    const url = `${BASE_URL}/api/v1/user/areas_in_block_manager`;
    let res = [];
    await axios
      .get(url) //, {withCredentials: true})
      .then((response) => (res = response.data.dt))
      .catch((error) => console.log(error));
    return res;
  },

  /*
   * Function: registerUser
   * Params: username, password, lastName, firstName, age, gender, phone, avatar, area
   * */
  async registerUser(
    username,
    password,
    email,
    lastName,
    firstName,
    age,
    gender,
    phone,
    avatar,
    area
  ) {
    const url = `${BASE_URL}/api/v1/user/register`;
    let res = [];
    await axios
      .post(
        url,
        {
          username: username,
          password: password,
          email: email,
          last_name: lastName,
          first_name: firstName,
          age: age,
          gender: gender,
          phone: phone,
          avatar: avatar,
          area: area,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        res = error.response;
      });
    return res;
  },

  /*
   * Function: updateUser
   * Params: username, password, lastName, firstName, age, gender, phone, avatar, area
   * */
  async updateUser(
    username,
    password,
    email,
    lastName,
    firstName,
    age,
    gender,
    phone,
    avatar,
    area
  ) {
    const url = `${BASE_URL}/api/v1/user/update`;
    let res = [];
    await axios
      .post(
        url,
        {
          username: username,
          password: password,
          email: email,
          last_name: lastName,
          first_name: firstName,
          age: age,
          gender: gender,
          phone: phone,
          avatar: avatar,
          area: area,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        res = error.response;
      });
    return res;
  },

  /*
   * Function: updateUser
   * Params: username, password, lastName, firstName, age, gender, phone, avatar, area
   * */
  async deactivateUser(username) {
    const url = `${BASE_URL}/api/v1/user/deactivate`;
    let res = [];
    await axios
      .post(
        url,
        {
          username: username,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        res = error.response;
      });
    return res;
  },

  /*
   * Function: getAllNotification
   * */
  async getAllNotification() {
    const url = `${BASE_URL}/api/v1/user/get_all_notification`;
    let res = [];
    await axios
      .get(url) //, {withCredentials: true})
      .then((response) => (res = response.data.dt))
      .catch((error) => console.log(error));
    return res;
  },

  /*
   * Function: setNotificationRead
   *
   * */
  async markNotificationRead(alarmDevice, sentDate) {
    const url = `${BASE_URL}/api/v1/user/mark_notification_read`;
    let res = [];
    await axios
      .post(url, {
        alarm_device: alarmDevice,
        sent_date: sentDate,
      }) //, {withCredentials: true})
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        res = error.response;
      });
    return res;
  },

  async getNotificationByID(notificationID) {
    const url = `${BASE_URL}/api/v1/user/get_notification?id_notification=${notificationID}`;
    let res = [];
    await axios
      .get(url) //, {withCredentials: true})
      .then((response) => (res = response.data.dt))
      .catch((error) => console.log(error));
    return res;
  },
};
