// noinspection NpmUsedModulesInstalled
import { getListFloorsByBlock } from "@/api/location";
import { store } from "@/store";
import floors from "../../api/floors";

// initial state
const state = {
  all: [],
  currentFloor: {},
};

// getters
const getters = {
  loadFloors(state) {
    return state.all;
  },
  floorsSelect(state) {
    const results = [];
    const defaultValue = { value: 0, text: "All" };
    results.push(defaultValue);
    state.all.forEach((floor) => {
      let element = { value: floor.id_floor, text: floor.name_floor };
      results.push(element);
    });
    return results;
  },
};

// mutations
const mutations = {
  reloadFloors(state, payload) {
    state.all = payload;
  },
  setFloorDetail(state, payload) {
    state.currentFloor = payload;
  },
};

// actions
const actions = {
  listFloorsByBlock({ commit }, payload) {
    const results = getListFloorsByBlock(payload);
    results.then(function (value) {
      commit("reloadFloors", value.arr_floor);
      store.dispatch("blocks/setBlockDetail", value.block);
    });
  },

  setFloorDetail({ commit }, payload) {
    commit("setFloorDetail", payload);
  },

  // results {
  //   "data": {
  //     "err": 0,
  //     "msg": "Added block successfully",
  //     "dt": null
  //   },
  //   "status": 200,
  //   "statusText": "OK",
  //   "detail": "error",
  //   "headers"
  //   "config"
  // }
  async addFloor(context, { blockId, floorName }) {
    const results = await floors.addFloor(blockId, floorName);
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        reject(results);
      } else {
        resolve(results);
      }
    });
  },

  async updateFloor(context, { floorId, floorName }) {
    const results = await floors.updateFloor(floorId, floorName);
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        reject(results);
      } else {
        resolve(results);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
