export default [
  {
    title: "English",
    icon: "gb",
    lang: "en",
  },
  {
    title: "Japanese",
    icon: "JP",
    lang: "ja",
  },
  {
    title: "Vietnamese",
    icon: "VN",
    lang: "vn",
  },
];
