import axios from "axios";

const BASE_URL = process.env.VUE_APP_SERVER_API;

export default {
  /*
   * Function: addArea
   * Params: areaName
   * */
  async addArea(floorId, areaName) {
    const url = `${BASE_URL}/api/v1/area/add`;
    let res = [];
    await axios
      .post(
        url,
        {
          id_floor: floorId,
          name_area: areaName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        res = error.response;
      });
    return res;
  },

  /*
   * Function: addArea
   * Params: areaName
   * */
  async updateArea(areaId, areaName) {
    const url = `${BASE_URL}/api/v1/area/update`;
    let res = [];
    await axios
      .post(
        url,
        {
          id_area: areaId,
          name_area: areaName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        res = error.response;
      });
    return res;
  },
};
