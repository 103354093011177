const state = {
  positions: [],
  count: 0,
};

const getters = {
  loadPositions(state) {
    return state.positions;
  },
  loadCount(state) {
    return state.count;
  },
};

const mutations = {
  async reloadPositions(state, payload) {
    state.positions = payload.results;
    state.count = payload.count;
  },
};

const actions = {
  // async listPositionsByPage ({commit}, payload) {
  //   const {results, count} = await getListPositions(payload)
  //   commit('reloadPositions', {results, count})
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
