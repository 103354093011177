import axios from "axios";

const BASE_URL = process.env.VUE_APP_SERVER_API;

export default {
  /*
   * Function: addBlock
   * Params: blockName
   * */
  async addBlock(blockName) {
    const url = `${BASE_URL}/api/v1/block/add`;
    let res = [];
    await axios
      .post(
        url,
        {
          name_block: blockName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        res = error.response;
      });
    return res;
  },

  /*
   * Function: addBlock
   * Params: blockName
   * */
  async updateBlock(blockId, blockName) {
    const url = `${BASE_URL}/api/v1/block/update`;
    let res = [];
    await axios
      .post(
        url,
        {
          id_block: blockId,
          name_block: blockName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        res = error.response;
      });
    return res;
  },
};
