import axios from "axios";

const BASE_URL = process.env.VUE_APP_SERVER_API;

export default {
  /*
   * Function: addFloor
   * Params: floorName
   * */
  async addFloor(blockId, floorName) {
    const url = `${BASE_URL}/api/v1/floor/add`;
    let res = [];
    await axios
      .post(
        url,
        {
          id_block: blockId,
          name_floor: floorName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        res = error.response;
      });
    return res;
  },

  /*
   * Function: addFloor
   * Params: floorName
   * */
  async updateFloor(floorId, floorName) {
    const url = `${BASE_URL}/api/v1/floor/update`;
    let res = [];
    await axios
      .post(
        url,
        {
          id_floor: floorId,
          name_floor: floorName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        res = error.response;
      });
    return res;
  },
};
