<template>
  <v-container grid-list-lg id="main">
    <div id="container">
      <v-layout row>
        <v-flex grow>
          <BreadcrumbsComponent :items="items" />
        </v-flex>
        <v-flex shrink>
          <v-btn
            color="primary"
            @click="(isCreate = true), openAlarmDeviceModal()"
            >Add Alarm Device</v-btn
          >
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <h1 id="alarm-device-title">
        {{ $t("manageInfoArea.alarmDeviceList") }}
      </h1>
      <v-divider></v-divider>
      <v-layout wrap rows>
        <v-flex
          md3
          xs3
          v-for="alarmDevice in alarmDevices"
          :key="alarmDevice.id_alarm_device"
          class="text-md-center text-sx-center"
        >
          <!--Safe: status = 1 => Safe | Status = 2 => Fall detected-->
          <v-card
            :id="alarmDevice.status <= 1 ? alarmDevice.id_alarm_device : cardId"
            @click.native="onClickAlarmDevice(alarmDevice)"
          >
            <v-card-title class="primary">
              <v-layout align-center column>
                <div slot="label">
                  <h3 class="white--text">
                    {{ $t("manageInfoArea.alarmDevice") }}
                    {{ alarmDevice.name_alarm_device }}
                  </h3>
                </div>
              </v-layout>
            </v-card-title>
            <v-divider></v-divider>
            <v-card flat tile class="d-flex">
              <v-img
                :src="require('@/assets/images/sensor_icon.png')"
                aspect-ratio="1.1"
                contain
                class="grey lighten-2"
              >
                <v-layout slot="placeholder" align-center justify-center ma-0>
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-layout>
              </v-img>
            </v-card>
            <v-alert
              :value="true"
              color="success"
              :icon="alarmDevice.status <= 1 ? 'check_circle' : 'priority_high'"
              outline
              @click.stop="openConfirmUpdateStatusModal(alarmDevice)"
            >
              <!-- using click.stop to prevent firing click event on parent -->
              <v-btn
                @click.stop="
                  (isCreate = false), openAlarmDeviceModal(alarmDevice)
                "
                absolute
                color="orange darken-1"
                class="white--text"
                fab
                small
                right
                top
              >
                <v-icon>edit</v-icon>
              </v-btn>
              {{ alarmDevice.status <= 1 ? safeMessage : warningMessage }}
            </v-alert>
          </v-card>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
    </div>
    <footer-component id="footer"></footer-component>

    <vue-js-modal
      name="alarm-device-modal"
      @before-open="beforeOpenAlarmDeviceModal"
      :clickToClose="false"
    >
      <v-container>
        <v-layout align-center column>
          <h1>
            {{
              isCreate
                ? "Add Alarm Device"
                : "Update Alarm Device " +
                  alarmDeviceOnEditing.name_alarm_device
            }}
          </h1>
        </v-layout>
        <v-form
          ref="alarmDeviceForm"
          v-on="{ submit: isCreate ? addAlarmDevice : updateAlarmDevice }"
          onsubmit="return false;"
          v-model="isValid"
        >
          <input
            autocomplete="off"
            name="hidden"
            type="search"
            style="display: none"
          />
          <v-text-field
            name="alarmDeviceName"
            :label="labelAlarmDeviceName"
            type="text"
            v-model="model.name_alarm_device"
            :rules="alarmDeviceNameRules"
            class="mb-1"
          />
          <v-text-field
            name="alarmDeviceSerialNumber"
            :label="labelAlarmDeviceSerialNumber"
            type="text"
            v-model="model.serial_number"
            :rules="alarmDeviceSerialNumberRules"
            class="mb-1"
          />
          <v-layout justify-space-between row>
            <v-layout row>
              <v-btn
                color="red"
                @click="$modal.hide('alarm-device-modal')"
                :disabled="loading"
                >Close</v-btn
              >
              <v-btn
                color="warning"
                @click="$refs.alarmDeviceForm.reset()"
                :disabled="loading"
                >Clear All</v-btn
              >
            </v-layout>
            <v-btn
              type="submit"
              color="success"
              :loading="loading"
              :disabled="!isValid"
              >Confirm</v-btn
            >
          </v-layout>
        </v-form>
      </v-container>
    </vue-js-modal>

    <vue-js-modal
      name="confirm-modal"
      height="auto"
      @before-open="beforeOpenAlarmDeviceModal"
    >
      <v-container>
        <v-layout align-center column>
          <h1>{{ "Update Status" }}</h1>
        </v-layout>
        <v-card-text>
          {{ $t("text.confirmUpdateStatusAlarmDevice") }}
        </v-card-text>
        <v-layout justify-space-between row>
          <v-btn @click="$modal.hide('confirm-modal')" :disabled="loading"
            >Close</v-btn
          >
          <v-btn color="red" :loading="loading" @click="updateStatusAlarmDevice"
            >Confirm</v-btn
          >
        </v-layout>
      </v-container>
    </vue-js-modal>
  </v-container>
</template>

<script>
import FooterComponent from "./containers/Footer";
import BreadcrumbsComponent from "./containers/Breadcrumbs";
import Swal from "sweetalert2";

export default {
  name: "AlarmDeviceListManagement",
  components: {
    FooterComponent,
    BreadcrumbsComponent,
  },
  data() {
    return {
      safeMessage: "Everything is good.",
      warningMessage: "Fall Detected.",
      cardId: "card-alarm-device",
      items: [
        {
          text: "Blocks",
          disabled: false,
          href: "/#/location-management",
        },
        {
          text: "Floors",
          disabled: false,
          href:
            "/#/floor-management/" +
            this.$store.state.blocks.currentBlock.id_block,
        },
        {
          text: "Areas",
          disabled: false,
          href:
            "/#/area-management/" +
            this.$store.state.floors.currentFloor.id_floor,
        },
        {
          text: "Alarm Devices",
          disabled: true,
          href: "/",
        },
      ],
      loading: false,
      isValid: true,
      isCreate: true,
      showText: false,
      model: {
        alarmDeviceId: null,
        name_alarm_device: null,
        serial_number: null,
      },
      alarmDeviceOnEditing: {
        alarmDeviceId: null,
        name_alarm_device: null,
        serial_number: null,
      },
      labelAlarmDeviceName: this.$t(`manageInfoArea.alarmDeviceName`),
      labelAlarmDeviceSerialNumber: this.$t(
        `manageInfoArea.alarmDeviceSerialNumber`
      ),
      alarmDeviceNameRules: [
        (v) => !!v || this.$t(`rulesMessages.alarmDeviceNameRequired`),
      ],
      alarmDeviceSerialNumberRules: [
        (v) => !!v || this.$t(`rulesMessages.alarmDeviceSerialNumberRequired`),
      ],
    };
  },
  computed: {
    alarmDevices() {
      return this.$store.state.alarmDevices.all;
    },
    currentBlock() {
      return this.$store.state.blocks.currentBlock.id_block;
    },
    currentFloor() {
      return this.$store.state.floors.currentFloor.id_floor;
    },
    currentArea() {
      return this.$route.params.area;
    },
    areaName() {
      return this.$store.state.areas.currentArea.name_area;
    },
  },
  created() {
    this.$store.dispatch("alarmDevices/listAllAlarmDevices", this.currentArea);
  },
  methods: {
    onClickAlarmDevice(id, name) {},

    openAlarmDeviceModal(alarmDeviceModal) {
      this.$modal.show("alarm-device-modal", { ...alarmDeviceModal });
    },

    openConfirmUpdateStatusModal(alarmDeviceModal) {
      if (alarmDeviceModal.status > 1) {
        this.$modal.show("confirm-modal", {
          ...alarmDeviceModal,
        });
      }
    },

    beforeOpenAlarmDeviceModal(event) {
      this.model = event.params;
      this.alarmDeviceOnEditing = event.params;
    },

    addAlarmDevice() {
      this.loading = true;
      this.$store
        .dispatch("alarmDevices/addAlarmDevice", {
          areaId: this.currentArea,
          alarmDeviceName: this.model.name_alarm_device,
          alarmDeviceSerialNumber: this.model.serial_number,
        })
        .then((resolve) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            "reload"
          );
          this.$refs.alarmDeviceForm.reset();
          this.$modal.hide("alarm-device-modal");
        })
        .catch((reject) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Adding alarm device failed: " + reject.data.msg,
            "",
            ""
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
          // this.$refs.alarmDeviceForm.reset()
          // this.$modal.hide('alarm-device-modal')
        });
    },

    updateAlarmDevice() {
      this.loading = true;
      this.$store
        .dispatch("alarmDevices/updateAlarmDevice", {
          alarmDeviceId: this.model.id_alarm_device,
          alarmDeviceName: this.model.name_alarm_device,
          alarmDeviceSerialNumber: this.model.serial_number,
        })
        .then((resolve) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            "reload"
          );
          this.$refs.alarmDeviceForm.reset();
          this.$modal.hide("alarm-device-modal");
        })
        .catch((reject) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Updating alarm device failed: " + reject.data.msg,
            "",
            ""
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
          // this.$refs.alarmDeviceForm.reset()
          // this.$modal.hide('alarm-device-modal')
        });
    },

    updateStatusAlarmDevice() {
      this.loading = true;
      this.$store
        .dispatch("alarmDevices/updateStatus", {
          alarmDeviceId: this.model.id_alarm_device,
        })
        .then((resolve) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            "reload"
          );
          this.$modal.hide("confirm-modal");
        })
        .catch((reject) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Update Status Alarm Device failed: " + reject.data.msg,
            "",
            ""
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
        });
    },
  },
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 100px;
}

.disabled {
  color: grey;
  pointer-events: none;
}

#alarm-device-title {
  text-align: center;
  margin: 10px;
}

#card-alarm-device {
  border: none;
  color: #ffffff;
  /*cursor: pointer;*/
  display: inline-block;
  width: 100%;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

@-webkit-keyframes glowing {
  0% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -webkit-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -moz-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}

@keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}
</style>
