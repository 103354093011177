<template>
  <v-app id="login" class="primary">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-1 pa-3">
              <div class="d-flex align-center justify-center">
                <v-img
                  :src="require('@/assets/images/app_logo_small.png')"
                  max-width="150"
                />
              </div>
              <v-card-text>
                <div class="layout column align-center">
                  <h1 class="flex my-4 primary--text">
                    Fall Detection Management
                  </h1>
                </div>
                <v-form
                  @submit="login"
                  onsubmit="return false;"
                  v-model="isValid"
                >
                  <v-text-field
                    append-icon="person"
                    name="username"
                    :label="labelUsername"
                    type="text"
                    v-model="model.username"
                    :rules="usernameRules"
                    required
                  ></v-text-field>
                  <v-text-field
                    append-icon="lock"
                    name="password"
                    :label="labelPassword"
                    id="password"
                    type="password"
                    v-model="model.password"
                    :rules="passwordRules"
                    required
                  ></v-text-field>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      type="submit"
                      color="primary"
                      :loading="loading"
                      :disabled="!isValid"
                      >Login</v-btn
                    >
                  </v-card-actions>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: "PageLogin",
  data: function () {
    return {
      loading: false,
      isValid: true,
      model: {
        username: null,
        password: null,
      },
      labelUsername: this.$t(`login.username`),
      labelPassword: this.$t(`login.password`),
      usernameRules: [
        (v) => !!v || this.$t(`rulesMessages.usernameRequired`), // 'Username is required!'
      ],
      passwordRules: [
        (v) => !!v || this.$t(`rulesMessages.passwordRequired`), // 'Password is required!'
      ],
    };
  },
  methods: {
    login() {
      this.loading = true;
      this.$store
        .dispatch("auth/login", {
          username: this.model.username,
          password: this.model.password,
        })
        .then((resolve) => {
          console.log("Login successfully!");
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            "Login successfully!",
            "/",
            "push"
          );
          this.$store
            .dispatch("user/updateFirebaseToken", {
              macAddress: this.$store.state.user.fingerprint,
              deviceName: "PC",
              appToken: this.$store.state.user.firebaseToken,
              appName: "FES_Web_Manager",
            })
            .then((resolve) => {
              // console.log(resolve);
            })
            .catch((reject) => {
              console.log(reject);
            });
        })
        .catch((reject) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Login failed: " + reject.data,
            "",
            ""
          );
          console.log("Login error:", reject);
        });
    },
  },
};
</script>

<style scoped lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>
