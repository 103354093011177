// noinspection NpmUsedModulesInstalled
import { getListCameraByArea } from "@/api/location";
import cameras from "../../api/cameras";

// initial state
const state = {
  all: [],
};

// getters
const getters = {
  listCameras: (state, getters) => {
    return state.all;
  },
};

// mutations
const mutations = {
  reloadCameras(state, payload) {
    state.all = payload;
  },
};

// actions
const actions = {
  listAllCamera({ commit }, payload) {
    // const results = getListCameraByFloor(payload)
    const results = getListCameraByArea(payload);
    results.then(function (value) {
      commit("reloadCameras", value);
    });
  },

  async addCamera(context, { areaId, cameraName, streamUrl }) {
    const results = await cameras.addCamera(areaId, cameraName, streamUrl);
    // results {
    //   "data": {
    //     "err": 0,
    //     "msg": "Added block successfully",
    //     "dt": null
    //   },
    //   "status": 200,
    //   "statusText": "OK",
    //   "detail": "error",
    //   "headers"
    //   "config"
    // }
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        if (results.data.err === 3) {
          results.data.msg =
            "Your company are having maximum camera! Please contact administrator to increase number of camera you can have.";
        }
        reject(results);
      } else {
        resolve(results);
      }
    });
  },

  async updateCamera(context, { cameraId, cameraName, streamUrl }) {
    const results = await cameras.updateCamera(cameraId, cameraName, streamUrl);
    // results {
    //   "data": {
    //     "err": 0,
    //     "msg": "Added block successfully",
    //     "dt": null
    //   },
    //   "status": 200,
    //   "statusText": "OK",
    //   "detail": "error",
    //   "headers"
    //   "config"
    // }
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        reject(results);
      } else {
        resolve(results);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
