<template>
  <v-container id="container">
    <div id="main">
      <BreadcrumbsComponent :items="items" />
      <v-divider></v-divider>
      <v-layout justify-center>
        <v-layout row wrap id="video-wrapper">
          <v-card id="card">
            <v-card-title id="camera-title" primary-title>
              <h3 class="headline mb-0">Camera {{ cameraName }}</h3>
            </v-card-title>
            <camera-showing></camera-showing>
            <v-container fill-height fluid pa-0> </v-container>
          </v-card>
        </v-layout>
      </v-layout>
      <v-divider></v-divider>
    </div>
    <footer-component></footer-component>
  </v-container>
</template>

<script>
import FooterComponent from "./containers/Footer";
import BreadcrumbsComponent from "./containers/Breadcrumbs";
export default {
  name: "CamerasManager",
  components: {
    FooterComponent,
    BreadcrumbsComponent,
  },
  data() {
    return {
      items: [
        {
          text: "Blocks",
          disabled: false,
          href: "/",
        },
        {
          text: "Floors",
          disabled: false,
          href: "/#/floor-management/" + this.$route.params.block,
        },
        {
          text: "Areas",
          disabled: false,
          href:
            "/#/area-management/" +
            this.$route.params.block +
            "/" +
            this.$route.params.floor,
        },
        {
          text: "Cameras",
          disabled: false,
          href:
            "/#/camera-list-management/" +
            this.$route.params.block +
            "/" +
            this.$route.params.floor +
            "/" +
            this.$route.params.area,
        },
        {
          text: this.$route.params.camera,
          disabled: true,
          href: "/",
        },
      ],
    };
  },
  computed: {
    camera() {
      console.log("Current camera id: ", this.$route.params.camera);
      return this.$route.params.camera;
    },
    cameraName() {
      console.log("Current camera name: ", this.$route.query.camera_name);
      return this.$route.query.camera_name;
    },
  },
  created() {},
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 100px;
}
.disabled {
  color: grey;
  pointer-events: none;
}

.headline {
  text-align: center;
}

#card {
  margin: 0 auto;
  float: none;
  margin-bottom: 10px;
}

#video-wrapper {
  display: flex;
  justify-content: left; /* center horizontally */
  align-items: center; /* center vertically */
  padding: 20px;
}

#camera-title {
  text-align: center;
}
</style>
