import axios from "axios";

const BASE_URL = process.env.VUE_APP_SERVER_API;

// axios.defaults.withCredentials = true
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("JWT_Access");

/*
 * Function: getListAllBlocks
 * Params:
 * */
async function getListAllBlocks() {
  const url = `${BASE_URL}/api/v1/block`;
  let res = [];
  await axios
    .get(url) //, {withCredentials: true})
    .then((response) => (res = response.data.dt))
    .catch((error) => console.log(error));
  return res;
}

/*
 * Function: getListFloorByBlock
 * Params: block_id
 * */
async function getListFloorsByBlock(block) {
  const url = `${BASE_URL}/api/v1/floor?id_block=${block}`;
  let res = {};
  await axios
    .get(url)
    .then((response) => (res = response.data.dt))
    .catch((error) => console.log(error));
  return res;
}

/*
 * Function: getListAreaByFloor
 * Params: floorId
 * */
async function getListAreaByFloor(floorId) {
  const url = `${BASE_URL}/api/v1/area?id_floor=${floorId}`;
  let res = [];
  await axios
    .get(url)
    .then((response) => (res = response.data.dt))
    .catch((error) => console.log(error));
  return res;
}

/*
 * Function: getListCameraByArea
 * Params: areaId
 * */
async function getListCameraByArea(areaId) {
  const url = `${BASE_URL}/api/v1/camera/area?id_area=${areaId}`;
  let res = [];
  await axios
    .get(url)
    .then((response) => (res = response.data.dt))
    .catch((error) => console.log(error));
  return res;
}

/*
 * Function: getListCameraByArea
 * Params: areaId
 * */
async function getListAlarmDeviceByArea(areaId) {
  const url = `${BASE_URL}/api/v1/alarm_device/area?id_area=${areaId}`;
  let res = [];
  await axios
    .get(url)
    .then((response) => (res = response.data.dt))
    .catch((error) => console.log(error));
  return res;
}

/*
 * Function: getListCameraByFloor
 * Params: floor_id
 * */
async function getListCameraByFloor(floor) {
  const url = `${BASE_URL}/api/v1/camera/floor?id_floor=${floor}`;
  let res = [];
  await axios
    .get(url)
    .then((response) => (res = response.data.dt))
    .catch((error) => console.log(error));
  return res;
}

/*
 * Function: getUsersStatusByFloor
 * Params: block_id, floor_id
 *   floor_id = 0 => get all users status in block
 *   floor_id != 0 => get all users status in floor
 * */
async function getUsersStatusByFloor(block, floor) {
  const url = `${BASE_URL}/api/v1/user/get-status-user-by-floor?block=${block}&floor=${floor}`;
  let res = [];
  await axios
    .get(url)
    .then((response) => (res = response.data.dt))
    .catch((error) => console.log(error));
  return res;
}

async function updateAlarmDeviceNormalStatus(id_alarm_device) {
  const url = `${BASE_URL}/api/v1/alarm_device/update_status`;
  let res = [];
  await axios
    .post(url, { id_alarm_device })
    .then((response) => (res = response))
    .catch((error) => console.log(error));
  return res;
}

async function updateAlarmDeviceNormalStatusByBlock(id_block) {
  const url = `${BASE_URL}/api/v1/alarm_device/block/update_status`;
  let res = [];
  await axios
    .post(url, { id_block })
    .then((response) => (res = response))
    .catch((error) => console.log(error));
  return res;
}

async function updateAlarmDeviceNormalStatusByFloor(id_floor) {
  const url = `${BASE_URL}/api/v1/alarm_device/floor/update_status`;
  let res = [];
  await axios
    .post(url, { id_floor })
    .then((response) => (res = response))
    .catch((error) => console.log(error));
  return res;
}

async function updateAlarmDeviceNormalStatusByArea(id_area) {
  const url = `${BASE_URL}/api/v1/alarm_device/area/update_status`;
  let res = [];
  await axios
    .post(url, { id_area })
    .then((response) => (res = response))
    .catch((error) => console.log(error));
  return res;
}

export {
  getListAllBlocks,
  getListFloorsByBlock,
  getListAreaByFloor,
  getListCameraByArea,
  getListAlarmDeviceByArea,
  getListCameraByFloor,
  getUsersStatusByFloor,
  updateAlarmDeviceNormalStatus,
  updateAlarmDeviceNormalStatusByBlock,
  updateAlarmDeviceNormalStatusByFloor,
  updateAlarmDeviceNormalStatusByArea,
};
