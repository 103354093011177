<template>
  <v-container grid-list-lg id="main">
    <div id="container">
      <v-layout row>
        <v-flex grow>
          <BreadcrumbsComponent :items="items" />
        </v-flex>
        <v-flex shrink>
          <v-btn color="primary" @click="(isCreate = true), openCameraModal()"
            >Add Camera</v-btn
          >
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <h1 id="camera-title">{{ $t("manageInfoArea.cameraList") }}</h1>
      <v-divider></v-divider>
      <v-layout wrap rows>
        <v-flex
          md3
          xs3
          v-for="camera in cameras"
          :key="camera.id_camera"
          class="text-md-center text-sx-center"
        >
          <!--Safe: status = 1-->
          <v-card
            id="safe-camera"
            @click.native="onClickCamera(camera.id_camera, camera.name_camera)"
            v-if="camera.status === 1 || camera.status === 0"
          >
            <v-card-title class="primary">
              <v-layout align-center column>
                <div slot="label">
                  <h3 class="white--text">
                    {{ $t("manageInfoArea.camera") }} {{ camera.name_camera }}
                  </h3>
                </div>
              </v-layout>
            </v-card-title>
            <v-divider></v-divider>
            <v-card flat tile class="d-flex">
              <v-img
                :src="require('@/assets/images/camera-icon.png')"
                aspect-ratio="1.1"
                contain
                class="grey lighten-2"
              >
                <v-layout slot="placeholder" align-center justify-center ma-0>
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-layout>
              </v-img>
            </v-card>
            <v-alert :value="true" color="success" icon="check_circle" outline>
              <!-- using click.stop to prevent firing click event on parent -->
              <v-btn
                @click.stop="
                  (isCreate = false),
                    openCameraModal(camera.id_camera, camera.name_camera, null)
                "
                absolute
                color="orange darken-1"
                class="white--text"
                fab
                small
                right
                top
              >
                <v-icon>edit</v-icon>
              </v-btn>
              {{ safeMessage }}
            </v-alert>
          </v-card>

          <!--Status = 2 => Fire detected by System-->
          <v-card
            :id="cardId"
            @click.native="onClickCamera(camera.id_camera, camera.name_camera)"
            v-if="camera.status === 2"
          >
            <v-card-title class="primary">
              <v-layout align-center column>
                <div slot="label">
                  <h3 class="white--text">
                    {{ $t("manageInfoArea.camera") }} {{ camera.name_camera }}
                  </h3>
                </div>
              </v-layout>
            </v-card-title>
            <v-divider></v-divider>
            <v-card flat tile class="d-flex">
              <v-img
                :src="require('@/assets/images/camera-icon.png')"
                aspect-ratio="1.1"
                contain
                class="grey lighten-2"
              >
                <v-layout slot="placeholder" align-center justify-center ma-0>
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-layout>
              </v-img>
            </v-card>
            <v-alert :value="true" color="success" icon="check_circle" outline>
              <!-- using click.stop to prevent firing click event on parent -->
              <v-btn
                @click.stop="
                  (isCreate = false),
                    openCameraModal(camera.id_camera, camera.name_camera, null)
                "
                absolute
                color="orange darken-1"
                class="white--text"
                fab
                small
                right
                top
              >
                <v-icon>edit</v-icon>
              </v-btn>
              {{ warningMessage }}
            </v-alert>
          </v-card>

          <!--Status = 3 => Fire detected by System and confirm by Admin-->
          <v-card
            :id="cardId"
            @click.native="onClickCamera(camera.id_camera, camera.name_camera)"
            v-if="camera.status === 3"
          >
            <v-card-title class="primary">
              <v-layout align-center column>
                <div slot="label">
                  <h3 class="white--text">
                    {{ $t("manageInfoArea.camera") }} {{ camera.name_camera }}
                  </h3>
                </div>
              </v-layout>
            </v-card-title>
            <v-divider></v-divider>
            <v-card flat tile class="d-flex">
              <v-img
                :src="require('@/assets/images/camera-icon.png')"
                aspect-ratio="1.1"
                contain
                class="grey lighten-2"
              >
                <v-layout slot="placeholder" align-center justify-center ma-0>
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-layout>
              </v-img>
            </v-card>
            <v-alert :value="true" color="success" icon="check_circle" outline>
              <!-- using click.stop to prevent firing click event on parent -->
              <v-btn
                @click.stop="
                  (isCreate = false),
                    openCameraModal(camera.id_camera, camera.name_camera, null)
                "
                absolute
                color="orange darken-1"
                class="white--text"
                fab
                small
                right
                top
              >
                <v-icon>edit</v-icon>
              </v-btn>
              {{ warningMessage }}
            </v-alert>
          </v-card>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
    </div>
    <footer-component id="footer"></footer-component>

    <vue-js-modal
      name="camera-modal"
      @before-open="beforeOpenCameraModal"
      :clickToClose="false"
    >
      <v-container>
        <v-layout align-center column>
          <h1>
            {{
              isCreate
                ? "Add Camera"
                : "Update Camera " + cameraOnEditing.cameraName
            }}
          </h1>
        </v-layout>
        <v-form
          ref="cameraForm"
          v-on="{ submit: isCreate ? addCamera : updateCamera }"
          onsubmit="return false;"
          v-model="isValid"
        >
          <input
            autocomplete="off"
            name="hidden"
            type="search"
            style="display: none"
          />
          <v-text-field
            name="cameraName"
            :label="labelCameraName"
            type="text"
            v-model="model.cameraName"
            :rules="cameraNameRules"
            class="mb-1"
          ></v-text-field>
          <!-- need to use autocomplete="new-password" property to prevent browser autofill because misunderstanding these are username and password field -->
          <v-text-field
            autocomplete="new-password"
            name="streamUrl"
            :label="labelStreamUrl"
            id="streamUrl"
            v-model="model.streamUrl"
            :rules="isCreate ? streamUrlCreatingRules : streamUrlEditingRules"
            :append-icon="showText ? 'visibility' : 'visibility_off'"
            :type="showText ? 'text' : 'password'"
            @click:append="showText = !showText"
            class="mb-3"
          ></v-text-field>
          <!--          <v-text-field-->
          <!--            autocomplete="new-password"-->
          <!--            v-show="false"-->
          <!--            hidden-->
          <!--            name="streamUrl"-->
          <!--            v-model="cameraOnEditing.streamUrl"-->
          <!--          ></v-text-field>-->
          <v-layout justify-space-between row>
            <v-layout row>
              <v-btn
                color="red"
                @click="$modal.hide('camera-modal')"
                :disabled="loading"
                >Close</v-btn
              >
              <v-btn
                color="warning"
                @click="$refs.cameraForm.reset()"
                :disabled="loading"
                >Clear All</v-btn
              >
            </v-layout>
            <v-btn
              type="submit"
              color="success"
              :loading="loading"
              :disabled="!isValid"
              >Confirm</v-btn
            >
          </v-layout>
        </v-form>
      </v-container>
    </vue-js-modal>
  </v-container>
</template>

<script>
import FooterComponent from "./containers/Footer";
import BreadcrumbsComponent from "./containers/Breadcrumbs";
import Swal from "sweetalert2";

export default {
  name: "CameraListManagement",
  components: {
    FooterComponent,
    BreadcrumbsComponent,
  },
  data() {
    return {
      safeMessage: "Everything is good.",
      warningMessage: "Fall Detected.",
      cardId: "card-camera",
      items: [
        {
          text: "Blocks",
          disabled: false,
          href: "/",
        },
        {
          text: "Floors",
          disabled: false,
          href: "/#/floor-management/" + this.$route.params.block,
        },
        {
          text: "Areas",
          disabled: false,
          href:
            "/#/area-management/" +
            this.$route.params.block +
            "/" +
            this.$route.params.floor,
        },
        {
          text: "Cameras",
          disabled: true,
          href: "/",
        },
      ],
      loading: false,
      isValid: true,
      isCreate: true,
      showText: false,
      model: {
        cameraId: null,
        cameraName: null,
        streamUrl: null,
      },
      cameraOnEditing: {
        cameraId: null,
        cameraName: null,
        streamUrl: null,
      },
      labelCameraName: this.$t(`manageInfoArea.cameraName`),
      labelStreamUrl: this.$t(`manageInfoArea.streamUrl`),
      cameraNameRules: [
        (v) => !!v || this.$t(`rulesMessages.cameraNameRequired`),
      ],
      streamUrlCreatingRules: [
        (v) => !!v || this.$t(`rulesMessages.streamUrlRequired`),
        (v) =>
          /^(rtsp:\/\/).+/gim.test(v) || this.$t(`rulesMessages.streamUrlRTSP`),
      ],
      streamUrlEditingRules: [
        (v) =>
          /^(rtsp:\/\/).+/gim.test(v) ||
          !v ||
          this.$t(`rulesMessages.streamUrlRTSP`),
      ],
    };
  },
  computed: {
    cameras() {
      // console.log('Camera-State: ', this.$store.state.cameras.all)
      return this.$store.state.cameras.all;
    },
    currentBlock() {
      console.log("Current block: ", this.$route.params.block);
      return this.$route.params.block;
    },
    currentFloor() {
      console.log("Current floor: ", this.$route.params.floor);
      return this.$route.params.floor;
    },
    currentArea() {
      console.log("Current area: ", this.$route.params.area);
      return this.$route.params.area;
    },
  },
  created() {
    this.$store.dispatch("cameras/listAllCamera", this.currentArea);
  },
  methods: {
    onClickCamera(id, name) {
      console.log("On click camera: ", name);
      this.$router.push(
        "/camera-management/" +
          this.currentBlock +
          "/" +
          this.currentFloor +
          "/" +
          this.currentArea +
          "/" +
          id +
          "?camera_name=" +
          name
      );
    },

    openCameraModal(cameraId, cameraName, streamUrl) {
      this.$modal.show("camera-modal", {
        cameraId: cameraId,
        cameraName: cameraName,
        streamUrl: streamUrl,
      });
    },

    beforeOpenCameraModal(event) {
      this.model.cameraId = event.params.cameraId;
      this.model.cameraName = event.params.cameraName;
      this.model.streamUrl = null; // treat stream url like password, don't show old stream url and let user re-enter stream url if they want to update it
      this.cameraOnEditing.cameraId = event.params.cameraId;
      this.cameraOnEditing.cameraName = event.params.cameraName;
      // this.cameraOnEditing.streamUrl = event.params.streamUrl
    },

    addCamera() {
      this.loading = true;
      this.$store
        .dispatch("cameras/addCamera", {
          areaId: this.currentArea,
          cameraName: this.model.cameraName,
          streamUrl: this.model.streamUrl,
        })
        .then((resolve) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            "reload"
          );
          console.log(resolve.data.msg);
          this.$refs.cameraForm.reset();
          this.$modal.hide("camera-modal");
        })
        .catch((reject) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Adding camera failed: " + reject.data.msg,
            "",
            ""
          );
          console.log("Adding camera error:", reject);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
          // this.$refs.cameraForm.reset()
          // this.$modal.hide('camera-modal')
        });
    },

    updateCamera() {
      // let streamUrl = this.model.streamUrl
      // if (this.model.streamUrl === null) {
      //   streamUrl = this.cameraOnEditing.streamUrl
      // }

      this.loading = true;
      this.$store
        .dispatch("cameras/updateCamera", {
          cameraId: this.model.cameraId,
          cameraName: this.model.cameraName,
          streamUrl: this.model.streamUrl,
        })
        .then((resolve) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "green",
            resolve.data.msg,
            "",
            "reload"
          );
          console.log(resolve.data.msg);
          this.$refs.cameraForm.reset();
          this.$modal.hide("camera-modal");
        })
        .catch((reject) => {
          this.loading = false;
          window.getApp.$emit(
            "APP_NOTIFY_BY_SNACKBAR",
            true,
            "red",
            "Updating camera failed: " + reject.data.msg,
            "",
            ""
          );
          console.log("Updating camera error:", reject);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: reject.data.msg,
          });
          // this.$refs.cameraForm.reset()
          // this.$modal.hide('camera-modal')
        });
    },
  },
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 100px;
}

.disabled {
  color: grey;
  pointer-events: none;
}

#camera-title {
  text-align: center;
  margin: 10px;
}

#card-camera {
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

@-webkit-keyframes glowing {
  0% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -webkit-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -moz-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}

@keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}
</style>
