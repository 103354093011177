// noinspection NpmUsedModulesInstalled
import { getListAllBlocks, getUsersStatusByFloor } from "@/api/location";
import blocks from "../../api/blocks";

// initial state
const state = {
  all: [],
  currentBlock: {},
  usersStatus: {},
};

// getters
const getters = {
  // Get list SOS User
  sosList: (state) => {
    return state.usersStatus.sos;
  },
  // Get list Safe User
  safeList: (state) => {
    return state.usersStatus.safe;
  },
};

// mutations
const mutations = {
  reloadBlocks(state, payload) {
    state.all = payload;
  },
  usersStatus(state, payload) {
    state.usersStatus = payload;
  },
  setBlockDetail(state, payload) {
    state.currentBlock = payload;
  },
};

// actions
const actions = {
  listAllBlocks({ commit }) {
    const results = getListAllBlocks();
    results.then(function (value) {
      commit("reloadBlocks", value);
    });
  },

  listUserStatus({ commit }, payload) {
    const results = getUsersStatusByFloor(payload[0], payload[1]);
    results.then(function (value) {
      commit("usersStatus", value);
    });
  },

  setBlockDetail({ commit }, payload) {
    commit("setBlockDetail", payload);
  },

  async addBlock(context, { blockName }) {
    const results = await blocks.addBlock(blockName);
    // results {
    //   "data": {
    //     "err": 0,
    //     "msg": "Added block successfully",
    //     "dt": null
    //   },
    //   "status": 200,
    //   "statusText": "OK",
    //   "detail": "error",
    //   "headers"
    //   "config"
    // }
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        reject(results);
      } else {
        resolve(results);
      }
    });
  },

  async updateBlock(context, { blockId, blockName }) {
    const results = await blocks.updateBlock(blockId, blockName);
    // results {
    //   "data": {
    //     "err": 0,
    //     "msg": "Added block successfully",
    //     "dt": null
    //   },
    //   "status": 200,
    //   "statusText": "OK",
    //   "detail": "error",
    //   "headers"
    //   "config"
    // }
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        reject(results);
      } else {
        resolve(results);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
