// noinspection NpmUsedModulesInstalled
import { getListAreaByFloor } from "@/api/location";
import areas from "../../api/areas";
import { store } from "@/store";

// initial state
const state = {
  all: [],
  currentArea: {},
};

// getters
const getters = {
  loadAreas(state) {
    return state.all;
  },
  areasSelect(state) {
    const results = [];
    const defaultValue = { value: 0, text: "All" };
    results.push(defaultValue);
    state.all.forEach((area) => {
      let element = { value: area.id_area, text: area.name_area };
      results.push(element);
    });
    return results;
  },
};

// mutations
const mutations = {
  reloadAreas(state, payload) {
    state.all = payload;
  },
  setAreaDetail(state, payload) {
    state.currentArea = payload;
  },
};

// actions
const actions = {
  listAreasByFloor({ commit }, payload) {
    const results = getListAreaByFloor(payload);
    results.then(function (value) {
      commit("reloadAreas", value.arr_area);
      store.dispatch("floors/setFloorDetail", value.floor);
      store.dispatch("blocks/setBlockDetail", value.block);
    });
  },

  setAreaDetail({ commit }, payload) {
    commit("setAreaDetail", payload);
  },

  async addArea(context, { floorId, areaName }) {
    const results = await areas.addArea(floorId, areaName);
    // results {
    //   "data": {
    //     "err": 0,
    //     "msg": "Added block successfully",
    //     "dt": null
    //   },
    //   "status": 200,
    //   "statusText": "OK",
    //   "detail": "error",
    //   "headers"
    //   "config"
    // }
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        reject(results);
      } else {
        resolve(results);
      }
    });
  },

  async updateArea(context, { areaId, areaName }) {
    const results = await areas.updateArea(areaId, areaName);
    // results {
    //   "data": {
    //     "err": 0,
    //     "msg": "Added block successfully",
    //     "dt": null
    //   },
    //   "status": 200,
    //   "statusText": "OK",
    //   "detail": "error",
    //   "headers"
    //   "config"
    // }
    return new Promise((resolve, reject) => {
      if (results.status !== 200) {
        results.data.msg = results.data.detail;
        reject(results);
      } else if (results.data.err !== 0) {
        reject(results);
      } else {
        resolve(results);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
