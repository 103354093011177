import axios from "axios";
// import axiosCookieJarSupport from 'axios-cookiejar-support'
// import tough from 'tough-cookie'
const BASE_URL = process.env.VUE_APP_SERVER_API;

// axiosCookieJarSupport(axios)
// const cookieJar = new tough.CookieJar()
// axios.defaults.xsrfCookieName = 'csrftoken'
// axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
// axios.defaults.withCredentials = true
// axios.interceptors.response.use(response => {
//   // let data = response.data.dt
//   // console.log(data)
//   // Cookie.set('csrftoken', data.csrftoken, {expires: data.expires})
//   // Cookie.set('sessionid', data.sessionid, {expires: data.expires})
//   const sessionCookie = Cookie.get()
//   console.log('Cookie', sessionCookie)
//   console.log('response', response)
//   return response
// })

export default {
  /*
   * Function: login
   * Params: username, password
   * */
  async login(username, password) {
    const url = `${BASE_URL}/api/v1/authen/login`;
    let res = [];
    await axios
      .post(
        url,
        {
          username: username,
          password: password,
        },
        {
          // jar: cookieJar,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log('response', response)
        res = response;
        // console.log(cookieJar)
      })
      .catch((error) => {
        // console.log('error', error.response)
        res = error.response;
      });
    return res;
  },

  /*
   * Function: logout
   * */
  async logout(fingerprint, refreshToken) {
    const url = `${BASE_URL}/api/v1/authen/logout`;
    let res = [];
    await axios
      .post(url, {
        macAddress: fingerprint,
        app_name: "FES_Web_Manager",
        refresh_token: refreshToken,
      })
      .then((response) => (res = response.data))
      .catch((error) => {
        res = error.response;
        console.log(error.response);
      });
    return res;
  },

  /*
   * Function: isLoggedIn
   * */
  async isLoggedIn() {
    const url = `${BASE_URL}/api/v1/authen/is-logged-in`;
    let res = [];
    await axios
      .get(url)
      .then((response) => (res = response.data))
      .catch((error) => {
        res = error.response;
        console.log(error.response);
      });
    return res;
  },

  async refreshToken(refreshToken) {
    console.log("refreshToken");
    const url = `${BASE_URL}/api/v1/authen/token/refresh`;
    let res = [];
    await axios
      .post(url, {
        refresh: refreshToken,
      })
      .then((response) => (res = response.data))
      .catch((error) => {
        res = error.response;
        console.log(error.response);
      });
    return res;
  },
};
