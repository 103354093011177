<template>
  <v-footer dark height="auto">
    <v-card class="flex" flat tile>
      <v-card-title class="error">
        <strong class="subheading"> Fall Detection Management </strong>

        <v-spacer></v-spacer>

        <v-btn v-for="icon in icons" :key="icon" class="mx-3" dark icon>
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-actions class="justify-center">
        © 2024 AI Power. All Rights Reserved.
      </v-card-actions>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      icons: [
        // 'fab fa-facebook',
        // 'fab fa-twitter',
        // 'fab fa-google-plus',
        // 'fab fa-linkedin',
        // 'fab fa-instagram'
      ],
    };
  },
};
</script>

<style scoped></style>
